import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'element-plus/dist/index.css'
import SlideVerify from 'vue-monoplasty-slide-verify'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueCookies from 'vue-cookies'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://fa43885d76ac4ab7aa08452aa131b119@sentry.vtb.link/11',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'auth.vtb.link', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

app.use(VueCookies)
app.use(SlideVerify)
app.use(router).mount('#app')
